import { ReactNode } from 'react';

import { Stack, Typography } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';
import { ColorsLight } from 'libs/ui/theme';

import { EllipseStyled } from './styles';

interface Props {
  title: string;
  icon?: ReactNode;
}

export const Item = ({ title, icon }: Props) => (
  <Stack alignItems="center" direction="row" justifyContent="space-between">
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={16}
    >
      <EllipseStyled>
        {icon || <Icon.Plus color={ColorsLight.blue200} />}
      </EllipseStyled>
      <Typography variant="bodyBold">{title}</Typography>
    </Stack>
    <Icon.ArrowRight />
  </Stack>
);
