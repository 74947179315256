import { useColor } from 'hooks';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Upload = ({ size = 48, color, className }: SvgProps) => {
  const defaultColor = useColor({
    dark: ColorsDark.gray300,
    light: ColorsLight.gray150,
  });
  color = color || defaultColor;
  return (
    <SvgIcon className={className} size={size}>
      <svg
        fill="none"
        height="48"
        viewBox="0 0 49 48"
        width="49"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.2712 13.8222L23.7712 7.82219C24.036 7.61292 24.3637 7.49909 24.7012 7.49909C25.0387 7.49909 25.3664 7.61292 25.6312 7.82219L33.1312 13.8222C33.4045 14.0801 33.571 14.4313 33.5977 14.8061C33.6245 15.1809 33.5096 15.5522 33.2758 15.8464C33.042 16.1405 32.7062 16.3362 32.335 16.3947C31.9638 16.4532 31.5842 16.3702 31.2712 16.1622L26.2012 12.1122L26.2012 32.9922C26.2012 33.39 26.0432 33.7715 25.7619 34.0528C25.4806 34.3342 25.099 34.4922 24.7012 34.4922C24.3034 34.4922 23.9218 34.3342 23.6405 34.0528C23.3592 33.7715 23.2012 33.39 23.2012 32.9922L23.2012 12.1122L18.1312 16.1622C17.9802 16.3048 17.8011 16.4144 17.6055 16.4842C17.4099 16.554 17.2019 16.5824 16.9947 16.5676C16.7875 16.5528 16.5856 16.4951 16.4019 16.3982C16.2182 16.3013 16.0566 16.1674 15.9273 16.0048C15.7981 15.8422 15.704 15.6545 15.6511 15.4536C15.5982 15.2528 15.5875 15.0431 15.6198 14.8379C15.6522 14.6328 15.7268 14.4365 15.8389 14.2617C15.951 14.0868 16.0982 13.9372 16.2712 13.8222Z"
          fill={color}
        />
        <path
          d="M42.5 36V30C42.5 29.6022 42.342 29.2206 42.0607 28.9393C41.7794 28.658 41.3978 28.5 41 28.5C40.6022 28.5 40.2206 28.658 39.9393 28.9393C39.658 29.2206 39.5 29.6022 39.5 30V36C39.5 36.3978 39.342 36.7794 39.0607 37.0607C38.7794 37.342 38.3978 37.5 38 37.5H11C10.6022 37.5 10.2206 37.342 9.93934 37.0607C9.65804 36.7794 9.5 36.3978 9.5 36V30C9.5 29.6022 9.34196 29.2206 9.06066 28.9393C8.77936 28.658 8.39782 28.5 8 28.5C7.60218 28.5 7.22064 28.658 6.93934 28.9393C6.65804 29.2206 6.5 29.6022 6.5 30V36C6.5 37.1935 6.97411 38.3381 7.81802 39.182C8.66193 40.0259 9.80653 40.5 11 40.5H38C39.1935 40.5 40.3381 40.0259 41.182 39.182C42.0259 38.3381 42.5 37.1935 42.5 36Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};
