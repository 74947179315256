import { useColor } from 'hooks';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Eye = ({ size = 24, color, onClick }: SvgProps) => {
  const defaultColor = useColor({
    dark: ColorsDark.white,
    light: ColorsLight.black,
  });
  color = color || defaultColor;
  return (
    <SvgIcon size={size} onClick={onClick}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.91243 13.1718C2.57086 12.4286 2.57086 11.5714 2.91243 10.8282C4.49353 7.38843 7.96952 5 12.0034 5C16.0372 5 19.5132 7.38843 21.0943 10.8282C21.4358 11.5714 21.4358 12.4286 21.0943 13.1718C19.5132 16.6116 16.0372 19 12.0034 19C7.96952 19 4.49353 16.6116 2.91243 13.1718Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M15.0034 12C15.0034 13.6569 13.6602 15 12.0034 15C10.3465 15 9.00335 13.6569 9.00335 12C9.00335 10.3431 10.3465 9 12.0034 9C13.6602 9 15.0034 10.3431 15.0034 12Z"
          stroke={color}
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
};
