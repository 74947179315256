import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const UserStructureLight = ({ size = 62, className }: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="62"
      viewBox="0 0 62 62"
      width="62"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#E6ECFF" height="62" rx="31" width="62" />
      <path
        d="M45.6037 45.6479C47.1065 45.6479 48.3247 44.4297 48.3247 42.927C48.3247 41.4243 47.1065 40.2061 45.6037 40.2061C44.101 40.2061 42.8828 41.4243 42.8828 42.927C42.8828 44.4297 44.101 45.6479 45.6037 45.6479Z"
        fill="#5F94FF"
      />
      <path
        d="M40.7969 50.4544C40.7969 47.8242 42.9736 45.6475 45.6039 45.6475H45.6946C48.3248 45.6475 50.5015 47.8242 50.5015 50.4544H40.7969Z"
        fill="#5F94FF"
      />
      <path
        d="M16.3967 45.6479C17.8994 45.6479 19.1176 44.4297 19.1176 42.927C19.1176 41.4243 17.8994 40.2061 16.3967 40.2061C14.894 40.2061 13.6758 41.4243 13.6758 42.927C13.6758 44.4297 14.894 45.6479 16.3967 45.6479Z"
        fill="#5F94FF"
      />
      <path
        d="M11.5 50.4549C11.5 47.8247 13.6767 45.6479 16.307 45.6479H16.3977C19.0279 45.6479 21.2047 47.8247 21.2047 50.4549H11.5Z"
        fill="#5F94FF"
      />
      <path
        d="M30.9983 45.5571C32.501 45.5571 33.7192 44.3389 33.7192 42.8362C33.7192 41.3334 32.501 40.1152 30.9983 40.1152C29.4955 40.1152 28.2773 41.3334 28.2773 42.8362C28.2773 44.3389 29.4955 45.5571 30.9983 45.5571Z"
        fill="#5F94FF"
      />
      <path
        d="M26.1914 50.4544C26.1914 47.8242 28.3681 45.6475 30.9984 45.6475C33.6286 45.6475 35.8054 47.8242 35.8054 50.4544H26.1914Z"
        fill="#5F94FF"
      />
      <path
        d="M41.0707 19.6175V23.4268C41.0707 23.6989 40.7986 23.971 40.5265 23.971H38.259C37.8056 23.971 37.5335 24.5152 37.8963 24.878L39.5288 26.5105C39.7102 26.6919 39.7102 27.0547 39.5288 27.2361L36.8079 29.957C36.6265 30.1384 36.2637 30.1384 36.0823 29.957L34.4497 28.3245C34.087 27.9617 33.5428 28.2338 33.5428 28.6873V30.9547C33.5428 31.2268 33.2707 31.4989 32.9986 31.4989H29.1893C28.9172 31.4989 28.6451 31.2268 28.6451 30.9547V28.6873C28.6451 28.2338 28.1009 27.9617 27.7381 28.3245L26.1056 29.957C25.9242 30.1384 25.5614 30.1384 25.38 29.957L22.659 27.2361C22.4777 27.0547 22.4777 26.6919 22.659 26.5105L24.2916 24.878C24.6544 24.5152 24.3823 23.971 23.9288 23.971H21.6614C21.3893 23.971 21.1172 23.6989 21.1172 23.4268V19.6175C21.1172 19.3454 21.3893 19.0733 21.6614 19.0733H23.7474C24.2009 19.0733 24.473 18.5291 24.1102 18.1663L22.4777 16.5338C22.2963 16.3524 22.2963 15.9896 22.4777 15.8082L25.1986 13.0873C25.38 12.9059 25.7428 12.9059 25.9242 13.0873L27.5567 14.7198C27.9195 15.0826 28.4637 14.8105 28.4637 14.357V12.0896C28.4637 11.8175 28.7358 11.5454 29.0079 11.5454H32.8172C33.0893 11.5454 33.3614 11.8175 33.3614 12.0896V14.357C33.3614 14.8105 33.9056 15.0826 34.2683 14.7198L35.9009 13.0873C36.0823 12.9059 36.4451 12.9059 36.6265 13.0873L39.3474 15.8082C39.5288 15.9896 39.5288 16.3524 39.3474 16.5338L37.7149 18.1663C37.3521 18.5291 37.6242 19.0733 38.0777 19.0733H40.3451C40.7986 19.0733 41.0707 19.3454 41.0707 19.6175Z"
        fill="#256BF5"
      />
      <path
        d="M31.0002 25.6943C28.7328 25.6943 26.8281 23.7896 26.8281 21.5222C26.8281 19.2547 28.7328 17.3501 31.0002 17.3501C33.2677 17.3501 35.1723 19.2547 35.1723 21.5222C35.1723 23.7896 33.2677 25.6943 31.0002 25.6943ZM31.0002 20.071C30.1839 20.071 29.5491 20.7059 29.5491 21.5222C29.5491 22.3385 30.1839 22.9734 31.0002 22.9734C31.8165 22.9734 32.4514 22.3385 32.4514 21.5222C32.4514 20.7059 31.8165 20.071 31.0002 20.071Z"
        fill="#A1C0FF"
      />
      <path
        d="M45.6011 33.9478C37.0755 33.9478 28.55 33.9478 20.0244 33.9478C18.7546 33.9478 17.5755 33.9478 16.3058 33.9478C15.5802 33.9478 14.9453 34.5826 14.9453 35.3082V36.7594C14.9453 38.4826 17.6662 38.4826 17.6662 36.7594C17.6662 36.7594 17.6662 36.7594 17.6662 36.6687H29.5476V36.8501V37.0315C29.5476 37.6664 30.0918 38.3012 30.7267 38.3919C31.543 38.4826 32.2686 37.8478 32.2686 37.0315C32.2686 36.9408 32.2686 36.8501 32.2686 36.6687H41.8825H44.2407V36.7594C44.2407 38.4826 46.9616 38.4826 46.9616 36.7594C46.9616 36.3059 46.9616 35.8524 46.9616 35.3082C46.9616 34.5826 46.4174 33.9478 45.6011 33.9478Z"
        fill="#256BF5"
      />
    </svg>
  </SvgIcon>
);
