import { Body } from 'components';
import { useTranslation } from 'libs/i18n';
import { Button, Typography, Stack, Pressed } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import {
  ButtonsStyled,
  FormLayoutStyled,
} from './RegistrationSelectType.styles';

interface Props {
  onGoBack: () => void;
  onSelectPresonal?: () => void;
  onSelectLegal?: () => void;
}

export const RegistrationSelectType = ({
  onGoBack,
  onSelectPresonal,
  onSelectLegal,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormLayoutStyled center variant="filled-all">
      <Stack spacing={16}>
        <Body padding="small">
          <Pressed datax="registrationSelectType-backButton" onClick={onGoBack}>
            <Stack alignItems="center" direction="row" spacing={4}>
              <Icon.ArrowLeft />
              <Typography variant="bodyBold" variantMobile="captionBold">
                {t('auth.registration.selectType.goBack')}
              </Typography>
            </Stack>
          </Pressed>
        </Body>

        <Body className="registrationSelectType-content">
          <Stack spacing={32}>
            <Stack spacing={20}>
              <Typography
                align="center"
                datax="registrationSelectType-title"
                variant="title"
                variantMobile="subtitle"
              >
                {t('auth.registration.selectType.title')}
              </Typography>
              <Typography
                align="center"
                datax="registrationSelectType-subtitle"
              >
                {t('auth.registration.selectType.subtitle')}
              </Typography>
            </Stack>

            <ButtonsStyled>
              <Button
                fullWidth
                datax="registrationSelectType-personalButton"
                label={t('auth.registration.selectType.personalButton')}
                onClick={onSelectPresonal}
              />
              <Button
                fullWidth
                datax="registrationSelectType-legalButton"
                label={t('auth.registration.selectType.legalButton')}
                mode="outlined"
                onClick={onSelectLegal}
              />
            </ButtonsStyled>
          </Stack>
        </Body>
      </Stack>
    </FormLayoutStyled>
  );
};
