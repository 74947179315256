import { getColor } from 'helpers';
import { styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const ContentStyled = styled('div')(({ theme }) => ({
  padding: 20,
  borderWidth: 1,
  borderRadius: 12,
  borderColor: getColor(theme, {
    light: ColorsLight.blue100,
    dark: ColorsDark.blue250,
  }),
  borderStyle: 'dashed',
  outline: 'none',
}));

export const LineStyled = styled('div')(({ theme }) => ({
  backgroundColor: getColor(theme, {
    light: ColorsLight.blue100,
    dark: ColorsDark.gray200,
  }),
  height: 1,
}));
