import { useColor } from 'hooks';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const ArrowLeft = ({ size = 24, color, className }: SvgProps) => {
  const defaultColor = useColor({
    dark: ColorsDark.white,
    light: ColorsLight.black,
  });
  color = color || defaultColor;
  return (
    <SvgIcon className={className} size={size}>
      <svg
        fill="none"
        height="60"
        viewBox="0 0 60 60"
        width="60"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M39.2626 18.525L35.7376 15L20.7375 30L35.7376 45L39.2626 41.475L27.8125 30L39.2626 18.525Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};
