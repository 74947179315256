import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const CheckCircle = ({ size = 62, className }: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="62"
      viewBox="0 0 62 62"
      width="62"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3658_8904)">
        <path
          d="M31 0C13.9059 0 0 13.9059 0 31C0 48.0941 13.9059 62 31 62C48.0941 62 62 48.0941 62 31C62 13.9059 48.0941 0 31 0Z"
          fill="#C3EECA"
        />
        <path
          d="M46.7112 24.431L29.9194 41.2223C29.4156 41.7261 28.7544 41.9796 28.0931 41.9796C27.4318 41.9796 26.7705 41.7261 26.2667 41.2223L17.8711 32.8266C16.8607 31.8167 16.8607 30.1839 17.8711 29.174C18.881 28.1636 20.5134 28.1636 21.5237 29.174L28.0931 35.7433L43.0586 20.7783C44.0685 19.7679 45.7009 19.7679 46.7112 20.7783C47.7211 21.7882 47.7211 23.4206 46.7112 24.431Z"
          fill="#0E591A"
        />
      </g>
      <defs>
        <clipPath id="clip0_3658_8904">
          <rect fill="white" height="62" width="62" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
