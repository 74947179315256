import { useTheme } from 'libs/ui/theme';

import { SvgProps } from '../types';

import { CompanyDark } from './CompanyDark';
import { CompanyLight } from './CompanyLight';

export const Company = ({ size = 24, className }: SvgProps) => {
  const { mode } = useTheme();

  if (mode === 'dark') {
    return <CompanyDark className={className} size={size} />;
  }
  return <CompanyLight className={className} size={size} />;
};
