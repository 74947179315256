import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const CompanyDark = ({ size = 24, className }: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="62"
      viewBox="0 0 62 62"
      width="62"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5060_14818)">
        <circle cx="31.5" cy="31.5" fill="#C3FEFF" r="28.5" />
        <path
          d="M16.4543 59.4585H10.3919C9.62319 59.4585 9 58.7372 9 57.8476V35.4157C9 34.526 9.62319 33.8047 10.3919 33.8047H16.4543C17.223 33.8047 17.8462 34.526 17.8462 35.4157V57.8476C17.8462 58.7372 17.2231 59.4585 16.4543 59.4585Z"
          fill="#008C8F"
        />
        <path
          d="M51.9459 29.629L47.5606 24.5419C47.3048 24.245 46.9576 24.0781 46.5957 24.0781C46.2337 24.0781 45.8865 24.245 45.6307 24.5419L41.2455 29.629C40.9895 29.9259 40.8457 30.3285 40.8457 30.7484V57.8796C40.8457 58.7539 41.4567 59.4627 42.2104 59.4627H50.981C51.7347 59.4627 52.3457 58.7539 52.3457 57.8796V30.7484C52.3457 30.3285 52.202 29.9259 51.9459 29.629Z"
          fill="#00D5D9"
        />
        <path
          d="M42.0663 17H17.5091C16.7177 17 16.0762 17.7148 16.0762 18.5964V61.2308H43.4992V18.5964C43.4992 17.7148 42.8578 17 42.0663 17Z"
          fill="#00D7DB"
        />
        <path
          d="M43.4997 18.6603C43.4997 17.7433 42.8788 17 42.113 17H30.2305V63H43.4997V18.6603Z"
          fill="#00B2B6"
        />
        <path
          d="M23.9176 42.3291C23.1433 42.3291 22.5156 41.7014 22.5156 40.9271V39.4254C22.5156 38.6511 23.1433 38.0234 23.9176 38.0234C24.6918 38.0234 25.3196 38.6511 25.3196 39.4254V40.9271C25.3196 41.7014 24.6918 42.3291 23.9176 42.3291Z"
          fill="#F0F7FB"
        />
        <path
          d="M23.9176 34.8212C23.1433 34.8212 22.5156 34.1935 22.5156 33.4193V31.9176C22.5156 31.1433 23.1433 30.5156 23.9176 30.5156C24.6918 30.5156 25.3196 31.1433 25.3196 31.9176V33.4192C25.3196 34.1935 24.6918 34.8212 23.9176 34.8212Z"
          fill="#F0F7FB"
        />
        <path
          d="M23.9176 27.3133C23.1433 27.3133 22.5156 26.6856 22.5156 25.9114V24.4098C22.5156 23.6355 23.1433 23.0078 23.9176 23.0078C24.6918 23.0078 25.3196 23.6355 25.3196 24.4098V25.9115C25.3196 26.6857 24.6918 27.3133 23.9176 27.3133Z"
          fill="#F0F7FB"
        />
        <path
          d="M29.9195 27.3133C29.1453 27.3133 28.5176 26.6856 28.5176 25.9114V24.4098C28.5176 23.6355 29.1453 23.0078 29.9195 23.0078C30.6938 23.0078 31.3215 23.6355 31.3215 24.4098V25.9115C31.3215 26.6857 30.6938 27.3133 29.9195 27.3133Z"
          fill="#F0F7FB"
        />
        <path
          d="M35.9274 27.3133C35.1531 27.3133 34.5254 26.6856 34.5254 25.9114V24.4098C34.5254 23.6355 35.1531 23.0078 35.9274 23.0078C36.7016 23.0078 37.3293 23.6355 37.3293 24.4098V25.9115C37.3293 26.6857 36.7016 27.3133 35.9274 27.3133Z"
          fill="#F1F6FF"
        />
        <path
          d="M29.9254 34.8212C29.1511 34.8212 28.5234 34.1935 28.5234 33.4193V31.9176C28.5234 31.1433 29.1511 30.5156 29.9254 30.5156C30.6997 30.5156 31.3274 31.1433 31.3274 31.9176V33.4192C31.3274 34.1935 30.6997 34.8212 29.9254 34.8212Z"
          fill="#F0F7FB"
        />
        <path
          d="M29.9254 42.3291C29.1511 42.3291 28.5234 41.7014 28.5234 40.9271V39.4254C28.5234 38.6511 29.1511 38.0234 29.9254 38.0234C30.6997 38.0234 31.3274 38.6511 31.3274 39.4254V40.9271C31.3274 41.7014 30.6997 42.3291 29.9254 42.3291Z"
          fill="#F0F7FB"
        />
        <path
          d="M35.9274 34.8212C35.1531 34.8212 34.5254 34.1935 34.5254 33.4193V31.9176C34.5254 31.1433 35.1531 30.5156 35.9274 30.5156C36.7016 30.5156 37.3293 31.1433 37.3293 31.9176V33.4192C37.3293 34.1935 36.7016 34.8212 35.9274 34.8212Z"
          fill="#F1F6FF"
        />
        <path
          d="M35.9274 42.3291C35.1531 42.3291 34.5254 41.7014 34.5254 40.9271V39.4254C34.5254 38.6511 35.1531 38.0234 35.9274 38.0234C36.7016 38.0234 37.3293 38.6511 37.3293 39.4254V40.9271C37.3293 41.7014 36.7016 42.3291 35.9274 42.3291Z"
          fill="#F1F6FF"
        />
        <path
          d="M31.3258 25.9114V24.4098C31.3258 23.6355 30.6981 23.0078 29.9238 23.0078V27.3134C30.6981 27.3133 31.3258 26.6857 31.3258 25.9114Z"
          fill="#F1F6FF"
        />
        <path
          d="M31.3258 33.4193V31.9176C31.3258 31.1433 30.6981 30.5156 29.9238 30.5156V34.8212C30.6981 34.8212 31.3258 34.1935 31.3258 33.4193Z"
          fill="#F1F6FF"
        />
        <path
          d="M31.3258 40.9271V39.4254C31.3258 38.6511 30.6981 38.0234 29.9238 38.0234V42.3291C30.6981 42.3291 31.3258 41.7014 31.3258 40.9271Z"
          fill="#F1F6FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_5060_14818">
          <rect fill="white" height="57" rx="28.5" width="57" x="3" y="3" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
