import { useTheme } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

const documentDb = { dark: '#008082', light: '#3B7BFE' };
const documentFoldDb = { dark: '#00C6CA', light: '#A8C0F1' };

export const DocumentDownload = ({
  size = { width: 62, height: 56 },
  className,
}: SvgProps) => {
  const { mode } = useTheme();
  return (
    <SvgIcon className={className} size={size}>
      <svg
        fill="none"
        height="62"
        viewBox="0 0 56 62"
        width="56"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_684_5516)">
          <path
            d="M44 14.7656L32.1538 11.4844L28.7692 0H5.07692C2.27299 0 0 2.20358 0 4.92188V51.0781C0 53.7964 2.27299 56 5.07692 56H38.9231C41.727 56 44 53.7964 44 51.0781V14.7656Z"
            fill={documentDb[mode]}
          />
          <path
            d="M44 14.7656V51.0781C44 53.7964 41.727 56 38.9231 56H22V0H28.7692L32.1538 11.4844L44 14.7656Z"
            fill={documentDb[mode]}
          />
          <path
            d="M44.0003 14.7656H32.1541C30.2926 14.7656 28.7695 13.2891 28.7695 11.4844V0C29.2095 0 29.6495 0.164062 29.954 0.492297L43.4925 13.6173C43.8311 13.9125 44.0003 14.3391 44.0003 14.7656Z"
            fill={documentFoldDb[mode]}
          />
          <path
            d="M32.1543 26.3594H11.8466C10.9112 26.3594 10.1543 25.6256 10.1543 24.7188C10.1543 23.8119 10.9112 23.0781 11.8466 23.0781H32.1543C33.0897 23.0781 33.8466 23.8119 33.8466 24.7188C33.8466 25.6256 33.0897 26.3594 32.1543 26.3594Z"
            fill="#F5F9FE"
          />
          <path
            d="M32.1543 32.9219H11.8466C10.9112 32.9219 10.1543 32.1881 10.1543 31.2812C10.1543 30.3744 10.9112 29.6406 11.8466 29.6406H32.1543C33.0897 29.6406 33.8466 30.3744 33.8466 31.2812C33.8466 32.1881 33.0897 32.9219 32.1543 32.9219Z"
            fill="#F5F9FE"
          />
          <path
            d="M32.1543 39.4844H11.8466C10.9112 39.4844 10.1543 38.7506 10.1543 37.8438C10.1543 36.9369 10.9112 36.2031 11.8466 36.2031H32.1543C33.0897 36.2031 33.8466 36.9369 33.8466 37.8438C33.8466 38.7506 33.0897 39.4844 32.1543 39.4844Z"
            fill="#F5F9FE"
          />
          <path
            d="M25.3851 46.0469H11.8466C10.9112 46.0469 10.1543 45.3131 10.1543 44.4062C10.1543 43.4994 10.9112 42.7656 11.8466 42.7656H25.3851C26.3205 42.7656 27.0774 43.4994 27.0774 44.4062C27.0774 45.3131 26.3205 46.0469 25.3851 46.0469Z"
            fill="#F5F9FE"
          />
          <path
            d="M22 46.0469H25.3846C26.32 46.0469 27.0769 45.3131 27.0769 44.4062C27.0769 43.4994 26.32 42.7656 25.3846 42.7656H22V46.0469Z"
            fill="#F5F9FE"
          />
          <path
            d="M22 39.4844H32.1538C33.0892 39.4844 33.8462 38.7506 33.8462 37.8438C33.8462 36.9369 33.0892 36.2031 32.1538 36.2031H22V39.4844Z"
            fill="#F5F9FE"
          />
          <path
            d="M22 32.9219H32.1538C33.0892 32.9219 33.8462 32.1881 33.8462 31.2812C33.8462 30.3744 33.0892 29.6406 32.1538 29.6406H22V32.9219Z"
            fill="#F5F9FE"
          />
          <path
            d="M22 26.3594H32.1538C33.0892 26.3594 33.8462 25.6256 33.8462 24.7188C33.8462 23.8119 33.0892 23.0781 32.1538 23.0781H22V26.3594Z"
            fill="#F5F9FE"
          />
          <path
            d="M45.6754 38.1225C51.5108 38.9407 56 43.9459 56 50.0045C56 56.6277 50.6261 62 44.0001 62C39.4449 62 35.4731 59.4598 33.4494 55.7153C32.5271 54.0125 32 52.0651 32 49.9952C32 43.372 37.3742 38 44.0001 38C44.565 38.0095 45.12 38.0473 45.6754 38.1225Z"
            fill="#C0DCFF"
          />
          <path
            d="M48.8184 49.8071L44.9501 53.9746V43.7012C44.9501 43.1838 44.5266 42.7603 44.0088 42.7603C43.4913 42.7603 43.0678 43.1838 43.0678 43.7012V53.9746L39.1712 49.8071C38.8135 49.4308 38.2111 49.4022 37.8346 49.7598C37.4582 50.1174 37.4302 50.7103 37.7874 51.0866L43.2936 57.0039C43.4722 57.1922 43.7171 57.305 43.9805 57.305C44.2439 57.305 44.4886 57.1922 44.6677 57.0039L50.1735 51.0866C50.5312 50.7103 50.5122 50.1081 50.1357 49.7598C49.7598 49.4022 49.1762 49.4308 48.8184 49.8071Z"
            fill="#2755BE"
          />
        </g>
        <defs>
          <clipPath id="clip0_684_5516">
            <rect fill="white" height="62" width="56" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
