import { ClientType, PersonalData } from 'modules/onboarding/common/components';

import { useOwnerPersonalData } from '../hooks';
import { PageComponentProps } from '../types';

export const OwnerPersonalDataStep = ({ canGoBack }: PageComponentProps) => {
  const { goToBack, onSubmit, submitLoading, formError } =
    useOwnerPersonalData();

  return (
    <PersonalData
      canGoBack={canGoBack}
      clientType={ClientType.Client}
      formError={formError}
      submitLoading={submitLoading}
      onGoBack={goToBack}
      onSubmit={onSubmit}
    />
  );
};
