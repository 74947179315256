import {
  PowerOfAttorneyStep,
  CitizenshipStep,
  RegistrationAddressStep,
  TaxResidencyStep,
  OwnerPersonalDataStep,
  OwnerCitizenshipStep,
  OwnerTaxResidencyStep,
  OwnerRegistrationAddressStep,
  BiographyStep,
  OwnerBiographyStep,
  PepConnectionStep,
  ConfirmationDataStep,
  AccountsInOrganizationsStep,
  SignatureSamplesStep,
  DebtsStep,
  KycStep,
  OwnerPepConnectionStep,
  OwnerWithdrawalAccountsStep,
  OwnerDebtsStep,
  OwnerAccountsInOrganizationsStep,
  OwnerFinancingSourcesStep,
  OwnerOrderSubmissionContactsStep,
} from './steps';
import { RoutePage } from './types';

enum Page {
  powerOfAttorney = 'powerOfAttorney',
  citizenship = 'citizenship',
  taxResidency = 'taxResidency',
  registrationAddress = 'registrationAddress',
  ownerPersonalData = 'ownerPersonalData',
  ownerCitizenship = 'ownerCitizenship',
  ownerTaxResidency = 'ownerTaxResidency',
  ownerRegistrationAddress = 'ownerRegistrationAddress',
  biography = 'biography',
  ownerBiography = 'ownerBiography',
  pepConnection = 'pepConnection',
  ownerPepConnection = 'ownerPepConnection',
  confirmationData = 'confirmationData',
  bankAccounts = 'bankAccounts',
  signatureSamples = 'signatureSamples',
  debts = 'debts',
  kyc = 'kyc',
  ownerWithDrawalAccounts = 'ownerWithDrawalAccounts',
  ownerDebts = 'ownerDebts',
  ownerBankAccounts = 'ownerBankAccounts',
  ownerFinancingSources = 'ownerFinancingSources',
  ownerOrderSubmissionContacts = 'ownerOrderSubmissionContacts',
}

const getItem = (
  page: Page,
  component: RoutePage['component'],
  componentProps: RoutePage['componentProps'] = { canGoBack: true }
): [Page, RoutePage] => [page, { component, componentProps }];

export const pages = new Map<Page, RoutePage>([
  getItem(Page.powerOfAttorney, PowerOfAttorneyStep, { canGoBack: false }),
  getItem(Page.citizenship, CitizenshipStep),
  getItem(Page.taxResidency, TaxResidencyStep),
  getItem(Page.registrationAddress, RegistrationAddressStep),
  getItem(Page.ownerPersonalData, OwnerPersonalDataStep),

  getItem(Page.ownerCitizenship, OwnerCitizenshipStep, { canGoBack: false }),
  getItem(Page.ownerTaxResidency, OwnerTaxResidencyStep),
  getItem(Page.ownerRegistrationAddress, OwnerRegistrationAddressStep),
  getItem(Page.kyc, KycStep),

  getItem(Page.biography, BiographyStep, { canGoBack: false }),
  getItem(Page.bankAccounts, AccountsInOrganizationsStep),
  getItem(Page.pepConnection, PepConnectionStep),
  getItem(Page.debts, DebtsStep),
  getItem(Page.ownerBiography, OwnerBiographyStep),
  getItem(Page.ownerBankAccounts, OwnerAccountsInOrganizationsStep),
  getItem(Page.ownerFinancingSources, OwnerFinancingSourcesStep),
  getItem(Page.ownerPepConnection, OwnerPepConnectionStep),
  getItem(Page.ownerDebts, OwnerDebtsStep),
  getItem(Page.ownerWithDrawalAccounts, OwnerWithdrawalAccountsStep),
  getItem(Page.ownerOrderSubmissionContacts, OwnerOrderSubmissionContactsStep),
  getItem(Page.signatureSamples, SignatureSamplesStep),
  getItem(Page.confirmationData, ConfirmationDataStep),
]);
