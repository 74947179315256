import { useTranslation } from 'libs/i18n';
import { Link, Stack, Typography } from 'libs/ui';
import { Icon } from 'libs/ui/Icon';

import { ContainerStyled } from './styles';

export interface DownloadContentProps {
  title?: String;
  href: string;
}

export const DownloadContent = ({ title, href }: DownloadContentProps) => {
  const { t } = useTranslation();
  return (
    <ContainerStyled>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack alignItems="center" direction="row" spacing={16}>
          <Icon.DocumentDownload />
          <Stack>
            <Typography variant="bodyBold" variantMobile="captionBold">
              {title ?? t('common.sampleDocument')}
            </Typography>
          </Stack>
        </Stack>
        <Link href={href} target="_blank">
          <Icon.Download />
        </Link>
      </Stack>
    </ContainerStyled>
  );
};
