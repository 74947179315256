import { useColor } from 'hooks';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const User = ({ size = 62, color, className }: SvgProps) => {
  const defaultColor = useColor({
    dark: ColorsDark.blue250,
    light: ColorsLight.blue150,
  });
  const fillColor = useColor({
    dark: ColorsDark.blue100,
    light: ColorsLight.gray50,
  });
  color = color || defaultColor;
  return (
    <SvgIcon className={className} size={size}>
      <svg
        fill="none"
        height="62"
        viewBox="0 0 62 62"
        width="62"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4904_11847)">
          <circle cx="31.5" cy="31.5" fill={fillColor} r="28.5" />
          <path
            d="M42.7346 41.185C39.6615 38.6992 35.7528 37.208 31.000 37.208V63.2184L49.3934 57.88V55.1008C49.3934 54.5033 49.3633 53.9127 49.3058 53.3301C48.823 48.4376 46.3612 44.1185 42.7346 41.185Z"
            fill={color}
          />
          <path
            d="M42.6189 23.3113C42.6189 17.1817 37.6316 12.1943 31.000 12.1943V34.4283C37.6316 34.4283 42.6189 29.4423 42.6189 23.3113Z"
            fill={color}
          />
          <path
            d="M20.2712 41.1841C16.6446 44.1176 14.1827 48.4367 13.6999 53.3292C13.6423 53.9118 13.6123 54.5024 13.6123 55.0998V57.8791L31.5037 62.7586V37.207C27.253 37.2072 23.3443 38.6984 20.2712 41.1841Z"
            fill={color}
          />
          <path
            d="M31.5003 34.4283V12.1943C25.3707 12.1943 20.3833 17.1817 20.3833 23.3113C20.3833 29.4423 25.3707 34.4283 31.5003 34.4283Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_4904_11847">
            <rect fill="white" height="57" rx="28.5" width="57" x="3" y="3" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
