import { getColor } from 'helpers';
import { Stack } from 'libs/ui/Stack';
import { styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const ContainerStyled = styled(Stack)(() => ({
  '.fileContainer-content': {
    overflow: 'hidden',

    '.fileContainer-fileInfo': {
      overflow: 'hidden',

      '.fileContainer-fileName': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  },
}));

export const DocumentNumberStyled = styled('div')(({ theme }) => ({
  position: 'relative',
  height: 62,
  '.document-number': {
    position: 'absolute',
    backgroundColor: getColor(theme, {
      light: ColorsLight.blue50,
      dark: ColorsDark.blue50,
    }),
    bottom: 0,
    right: -12,
    height: 24,
    width: 24,
    borderRadius: 24,
    textAlign: 'center',

    '& > *': {
      color: getColor(theme, {
        light: ColorsLight.blue250,
        dark: ColorsDark.blue250,
      }),
      lineHeight: 1.8,
    },
  },
}));
