import { useTheme } from 'libs/ui/theme';

import { SvgProps } from '../types';

import { UserStructureDark } from './UserStructureDark';
import { UserStructureLight } from './UserStructureLight';

export const UserStructure = ({ size = 62, className }: SvgProps) => {
  const { mode } = useTheme();

  if (mode === 'dark') {
    return <UserStructureDark className={className} size={size} />;
  }
  return <UserStructureLight className={className} size={size} />;
};
