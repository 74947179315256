import { useColor } from 'hooks';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const EyeOff = ({ size = 24, color, className, onClick }: SvgProps) => {
  const defaultColor = useColor({
    dark: ColorsDark.white,
    light: ColorsLight.black,
  });
  color = color || defaultColor;
  return (
    <SvgIcon className={className} size={size} onClick={onClick}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M2.56095 11.1017C3.05722 10.8593 3.65599 11.0651 3.89834 11.5614C4.28644 12.3561 4.75926 13.0842 5.30163 13.7315C5.32943 13.7606 5.35513 13.791 5.37874 13.8224C6.17012 14.7446 7.10462 15.4975 8.13684 16.0385C8.16411 16.0493 8.19113 16.0614 8.21785 16.0748C8.24882 16.0904 8.27863 16.1073 8.30725 16.1255C9.44451 16.6901 10.695 16.9999 12 16.9999C13.3048 16.9999 14.5553 16.6901 15.6926 16.1255C15.7213 16.1073 15.7511 16.0904 15.7821 16.0748C15.8089 16.0614 15.836 16.0493 15.8633 16.0385C16.8944 15.498 17.828 14.7462 18.6189 13.8254C18.6439 13.7919 18.6713 13.7595 18.7011 13.7286C19.2425 13.0821 19.7145 12.3551 20.1021 11.5617C20.3446 11.0655 20.9434 10.8597 21.4396 11.1021C21.9358 11.3446 22.1416 11.9434 21.8991 12.4396C21.5708 13.1117 21.1878 13.7485 20.7563 14.342L22.207 15.7928C22.5976 16.1834 22.5976 16.8165 22.207 17.207C21.8165 17.5976 21.1834 17.5976 20.7928 17.207L19.4483 15.8625C18.8674 16.4341 18.2321 16.9411 17.5514 17.372L18.3938 19.0516C18.6414 19.5453 18.442 20.1462 17.9483 20.3938C17.4546 20.6414 16.8537 20.4419 16.6061 19.9483L15.7696 18.2804C14.8936 18.6271 13.9657 18.8569 13 18.9513V20.9999C13 21.5522 12.5523 21.9999 12 21.9999C11.4477 21.9999 11 21.5522 11 20.9999V18.9513C10.0343 18.8569 9.10635 18.6271 8.23037 18.2804L7.39387 19.9483C7.14628 20.4419 6.54536 20.6414 6.05168 20.3938C5.55801 20.1462 5.35853 19.5453 5.60612 19.0516L6.44854 17.372C5.76785 16.941 5.13262 16.434 4.55173 15.8624L3.20711 17.207C2.81658 17.5976 2.18342 17.5976 1.79289 17.207C1.40237 16.8165 1.40237 16.1834 1.79289 15.7928L3.24393 14.3418C2.81245 13.7482 2.42947 13.1113 2.1012 12.439C1.85884 11.9428 2.06468 11.344 2.56095 11.1017Z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    </SvgIcon>
  );
};
