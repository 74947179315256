import { getColor } from 'helpers';
import { styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const EllipseStyled = styled('div')(({ theme }) => ({
  backgroundColor: getColor(theme, {
    dark: ColorsDark.gray50,
    light: ColorsLight.gray50,
  }),
  height: 57,
  width: 57,
  borderRadius: 57,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
}));
