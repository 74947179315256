import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const UserStructureDark = ({ size = 62, className }: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="62"
      viewBox="0 0 62 62"
      width="62"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4871_29459)">
        <circle cx="31.5" cy="31.5" fill="#C3FEFF" r="28.5" />
        <path
          d="M43.8578 43.8547C45.1293 43.8547 46.1601 42.8239 46.1601 41.5524C46.1601 40.2808 45.1293 39.25 43.8578 39.25C42.5862 39.25 41.5554 40.2808 41.5554 41.5524C41.5554 42.8239 42.5862 43.8547 43.8578 43.8547Z"
          fill="#62E5E8"
        />
        <path
          d="M39.7881 47.9269C39.7881 45.7013 41.63 43.8594 43.8556 43.8594H43.9323C46.1579 43.8594 47.9998 45.7013 47.9998 47.9269H39.7881Z"
          fill="#62E5E8"
        />
        <path
          d="M19.1434 43.8547C20.415 43.8547 21.4458 42.8239 21.4458 41.5524C21.4458 40.2808 20.415 39.25 19.1434 39.25C17.8719 39.25 16.8411 40.2808 16.8411 41.5524C16.8411 42.8239 17.8719 43.8547 19.1434 43.8547Z"
          fill="#62E5E8"
        />
        <path
          d="M15 47.9269C15 45.7013 16.8419 43.8594 19.0675 43.8594H19.1442C21.3698 43.8594 23.2117 45.7013 23.2117 47.9269H15Z"
          fill="#62E5E8"
        />
        <path
          d="M31.5001 43.7766C32.7717 43.7766 33.8025 42.7458 33.8025 41.4742C33.8025 40.2027 32.7717 39.1719 31.5001 39.1719C30.2286 39.1719 29.1978 40.2027 29.1978 41.4742C29.1978 42.7458 30.2286 43.7766 31.5001 43.7766Z"
          fill="#62E5E8"
        />
        <path
          d="M27.4336 47.9269C27.4336 45.7013 29.2755 43.8594 31.5011 43.8594C33.7267 43.8594 35.5686 45.7013 35.5686 47.9269H27.4336Z"
          fill="#62E5E8"
        />
        <path
          d="M40.0204 21.8303V25.0536C40.0204 25.2838 39.7901 25.5141 39.5599 25.5141H37.6413C37.2576 25.5141 37.0273 25.9745 37.3343 26.2815L38.7157 27.6629C38.8692 27.8164 38.8692 28.1234 38.7157 28.2769L36.4134 30.5792C36.2599 30.7327 35.9529 30.7327 35.7994 30.5792L34.418 29.1978C34.111 28.8908 33.6505 29.1211 33.6505 29.5048V31.4234C33.6505 31.6537 33.4203 31.8839 33.1901 31.8839H29.9668C29.7365 31.8839 29.5063 31.6537 29.5063 31.4234V29.5048C29.5063 29.1211 29.0458 28.8908 28.7389 29.1978L27.3575 30.5792C27.204 30.7327 26.897 30.7327 26.7435 30.5792L24.4411 28.2769C24.2876 28.1234 24.2876 27.8164 24.4411 27.6629L25.8226 26.2815C26.1295 25.9745 25.8993 25.5141 25.5156 25.5141H23.5969C23.3667 25.5141 23.1365 25.2838 23.1365 25.0536V21.8303C23.1365 21.6001 23.3667 21.3698 23.5969 21.3698H25.3621C25.7458 21.3698 25.976 20.9094 25.6691 20.6024L24.2876 19.221C24.1342 19.0675 24.1342 18.7605 24.2876 18.607L26.59 16.3047C26.7435 16.1512 27.0505 16.1512 27.204 16.3047L28.5854 17.6861C28.8924 17.9931 29.3528 17.7628 29.3528 17.3791V15.4605C29.3528 15.2302 29.5831 15 29.8133 15H33.0366C33.2668 15 33.4971 15.2302 33.4971 15.4605V17.3791C33.4971 17.7628 33.9575 17.9931 34.2645 17.6861L35.6459 16.3047C35.7994 16.1512 36.1064 16.1512 36.2599 16.3047L38.5622 18.607C38.7157 18.7605 38.7157 19.0675 38.5622 19.221L37.1808 20.6024C36.8738 20.9094 37.1041 21.3698 37.4878 21.3698H39.4064C39.7901 21.3698 40.0204 21.6001 40.0204 21.8303Z"
          fill="#009093"
        />
        <path
          d="M31.4985 26.9746C29.5799 26.9746 27.9683 25.363 27.9683 23.4443C27.9683 21.5257 29.5799 19.9141 31.4985 19.9141C33.4172 19.9141 35.0288 21.5257 35.0288 23.4443C35.0288 25.363 33.4172 26.9746 31.4985 26.9746ZM31.4985 22.2164C30.8078 22.2164 30.2706 22.7536 30.2706 23.4443C30.2706 24.135 30.8078 24.6723 31.4985 24.6723C32.1892 24.6723 32.7265 24.135 32.7265 23.4443C32.7265 22.7536 32.1892 22.2164 31.4985 22.2164Z"
          fill="#2DFBFF"
        />
        <path
          d="M43.8566 33.9531C36.6425 33.9531 29.4285 33.9531 22.2145 33.9531C21.14 33.9531 20.1424 33.9531 19.0679 33.9531C18.454 33.9531 17.9167 34.4903 17.9167 35.1043V36.3322C17.9167 37.7904 20.2191 37.7904 20.2191 36.3322C20.2191 36.3322 20.2191 36.3322 20.2191 36.2555H30.2727V36.409V36.5625C30.2727 37.0997 30.7332 37.6369 31.2704 37.7136C31.9611 37.7904 32.575 37.2532 32.575 36.5625C32.575 36.4857 32.575 36.409 32.575 36.2555H40.71H42.7054V36.3322C42.7054 37.7904 45.0077 37.7904 45.0077 36.3322C45.0077 35.9485 45.0077 35.5648 45.0077 35.1043C45.0077 34.4903 44.5473 33.9531 43.8566 33.9531Z"
          fill="#009093"
        />
      </g>
      <defs>
        <clipPath id="clip0_4871_29459">
          <rect fill="white" height="57" rx="28.5" width="57" x="3" y="3" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
