import { Icon } from 'libs/ui/Icon';

import { ItemsList } from '../ItemsList';

export interface CompanyType {
  id: string;
  type: string;
  name: string;
}
export interface CompanyListProps {
  companies: CompanyType[];
  deleteLoading?: boolean;
  errorIds?: string[];
  errorText: string;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

export const CompanyList = ({
  companies,
  deleteLoading,
  errorIds,
  errorText,
  onEdit,
  onDelete,
}: CompanyListProps) => (
  <ItemsList
    deleteLoading={deleteLoading}
    errorIds={errorIds}
    errorText={errorText}
    items={companies.map((u) => ({ ...u, Icon: Icon.Company }))}
    onDelete={onDelete}
    onEdit={onEdit}
  />
);
