import { useColor } from 'hooks';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const Close = ({ size = 24, color, className }: SvgProps) => {
  const defaultColor = useColor({
    dark: ColorsDark.white,
    light: ColorsLight.black,
  });
  color = color || defaultColor;
  return (
    <SvgIcon className={className} size={size}>
      <svg
        color={color}
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4_400)">
          <path
            d="M12.6663 4.27325L11.7263 3.33325L7.99967 7.05992L4.27301 3.33325L3.33301 4.27325L7.05967 7.99992L3.33301 11.7266L4.27301 12.6666L7.99967 8.93992L11.7263 12.6666L12.6663 11.7266L8.93967 7.99992L12.6663 4.27325Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_4_400">
            <rect fill="white" height="16" width="16" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
