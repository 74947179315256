import { SvgIcon } from '../SvgIcon';
import { SvgProps } from '../types';

export const CompanyLight = ({ size = 24, className }: SvgProps) => (
  <SvgIcon className={className} size={size}>
    <svg
      fill="none"
      height="62"
      viewBox="0 0 62 62"
      width="62"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3658_8839)">
        <rect fill="#E6ECFF" height="62" rx="31" width="62" />
        <path
          d="M14.4266 60H7.57343C6.70448 60 6 59.1846 6 58.1789V32.8211C6 31.8154 6.70448 31 7.57343 31H14.4266C15.2955 31 16 31.8154 16 32.8211V58.1789C16 59.1846 15.2956 60 14.4266 60Z"
          fill="#2368F3"
        />
        <path
          d="M54.5481 26.2749L49.5908 20.5242C49.3016 20.1886 48.9091 20 48.4999 20C48.0908 20 47.6983 20.1886 47.4091 20.5242L42.4519 26.2749C42.1626 26.6105 42 27.0657 42 27.5403V58.2104C42 59.1987 42.6907 60 43.5427 60H53.4573C54.3093 60 55 59.1987 55 58.2104V27.5403C55 27.0657 54.8375 26.6105 54.5481 26.2749Z"
          fill="#6799FF"
        />
        <path
          d="M43.3802 12H15.6198C14.7253 12 14 12.808 14 13.8046V62H45V13.8046C45 12.808 44.2749 12 43.3802 12Z"
          fill="#699BFF"
        />
        <path
          d="M45 13.8768C45 12.8403 44.2981 12 43.4324 12H30V64H45V13.8768Z"
          fill="#4482FF"
        />
        <path
          d="M22.8627 40.6329C21.9874 40.6329 21.2778 39.9233 21.2778 39.048V37.3505C21.2778 36.4752 21.9874 35.7656 22.8627 35.7656C23.7379 35.7656 24.4475 36.4752 24.4475 37.3505V39.048C24.4475 39.9233 23.7379 40.6329 22.8627 40.6329Z"
          fill="#F0F7FB"
        />
        <path
          d="M22.8627 32.1465C21.9874 32.1465 21.2778 31.4369 21.2778 30.5617V28.8641C21.2778 27.9889 21.9874 27.2793 22.8627 27.2793C23.7379 27.2793 24.4475 27.9889 24.4475 28.8641V30.5616C24.4475 31.4369 23.7379 32.1465 22.8627 32.1465Z"
          fill="#F0F7FB"
        />
        <path
          d="M22.8627 23.6581C21.9874 23.6581 21.2778 22.9486 21.2778 22.0733V20.3758C21.2778 19.5006 21.9874 18.791 22.8627 18.791C23.7379 18.791 24.4475 19.5006 24.4475 20.3758V22.0734C24.4475 22.9487 23.7379 23.6581 22.8627 23.6581Z"
          fill="#F0F7FB"
        />
        <path
          d="M29.6483 23.6581C28.7731 23.6581 28.0635 22.9486 28.0635 22.0733V20.3758C28.0635 19.5006 28.7731 18.791 29.6483 18.791C30.5236 18.791 31.2331 19.5006 31.2331 20.3758V22.0734C31.2331 22.9487 30.5236 23.6581 29.6483 23.6581Z"
          fill="#F0F7FB"
        />
        <path
          d="M36.4398 23.6581C35.5646 23.6581 34.855 22.9486 34.855 22.0733V20.3758C34.855 19.5006 35.5646 18.791 36.4398 18.791C37.3151 18.791 38.0246 19.5006 38.0246 20.3758V22.0734C38.0246 22.9487 37.3151 23.6581 36.4398 23.6581Z"
          fill="#F1F6FF"
        />
        <path
          d="M29.6542 32.1446C28.7789 32.1446 28.0693 31.435 28.0693 30.5597V28.8622C28.0693 27.9869 28.7789 27.2773 29.6542 27.2773C30.5294 27.2773 31.239 27.9869 31.239 28.8622V30.5596C31.239 31.435 30.5294 32.1446 29.6542 32.1446Z"
          fill="#F0F7FB"
        />
        <path
          d="M29.6542 40.6329C28.7789 40.6329 28.0693 39.9233 28.0693 39.048V37.3505C28.0693 36.4752 28.7789 35.7656 29.6542 35.7656C30.5294 35.7656 31.239 36.4752 31.239 37.3505V39.048C31.239 39.9233 30.5294 40.6329 29.6542 40.6329Z"
          fill="#F0F7FB"
        />
        <path
          d="M36.4398 32.1446C35.5646 32.1446 34.855 31.435 34.855 30.5597V28.8622C34.855 27.9869 35.5646 27.2773 36.4398 27.2773C37.3151 27.2773 38.0246 27.9869 38.0246 28.8622V30.5596C38.0246 31.435 37.3151 32.1446 36.4398 32.1446Z"
          fill="#F1F6FF"
        />
        <path
          d="M36.4398 40.6329C35.5646 40.6329 34.855 39.9233 34.855 39.048V37.3505C34.855 36.4752 35.5646 35.7656 36.4398 35.7656C37.3151 35.7656 38.0246 36.4752 38.0246 37.3505V39.048C38.0246 39.9233 37.3151 40.6329 36.4398 40.6329Z"
          fill="#F1F6FF"
        />
        <path
          d="M31.2377 22.0733V20.3758C31.2377 19.5006 30.5281 18.791 29.6528 18.791V23.6582C30.5281 23.6581 31.2377 22.9487 31.2377 22.0733Z"
          fill="#F1F6FF"
        />
        <path
          d="M31.2377 30.5597V28.8622C31.2377 27.9869 30.5281 27.2773 29.6528 27.2773V32.1445C30.5281 32.1446 31.2377 31.435 31.2377 30.5597Z"
          fill="#F1F6FF"
        />
        <path
          d="M31.2377 39.048V37.3505C31.2377 36.4752 30.5281 35.7656 29.6528 35.7656V40.6329C30.5281 40.6329 31.2377 39.9233 31.2377 39.048Z"
          fill="#F1F6FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_3658_8839">
          <rect fill="white" height="62" rx="31" width="62" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
