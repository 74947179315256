import { useMemo } from 'react';

import { useTranslation } from 'libs/i18n';

import { Icon } from '../Icon';
import { LinkLabel } from '../LinkLabel';
import { Pressed } from '../Pressed';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

import { FileContainer } from './FileContainer';
import {
  ContainerStyled,
  DragActiveStyled,
  LineStyled,
} from './FilesUpload.styles';
import { FileUpload, FilesUploadProps } from './types';
import { useFilesUpload } from './useFilesUpload';

/**
 * UploadFiles component.
 */
export const FilesUpload = <T extends FileUpload>(
  props: FilesUploadProps<T>
) => {
  const { t } = useTranslation();

  const { files, name, onRemoveFile, isError, className, title, subtitle } =
    props;
  const {
    getInputProps,
    getRootProps,
    isDragAccept,
    isDragReject,
    isDragActive,
    isFocused,
    open,
    setShowFiles,
    showFiles,
    addDisabled,
  } = useFilesUpload(props);

  const filesContainer = useMemo(
    () =>
      files?.map((file, index) => (
        <FileContainer
          key={file.id}
          file={file}
          index={index}
          onRemoveFile={() => onRemoveFile?.(file, index)}
        />
      )),
    [files, onRemoveFile]
  );

  return (
    <ContainerStyled
      isError={isError}
      showFiles={showFiles}
      {...getRootProps({ isFocused, isDragAccept, isDragReject, className })}
    >
      <input {...getInputProps()} name={name} />

      {isDragActive ? (
        <DragActiveStyled>
          <Icon.Upload />
          <Typography>{t('components.filesUpload.dragactiveTitle')}</Typography>
        </DragActiveStyled>
      ) : (
        <Stack spacing={24}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Stack spacing={4}>
              <Typography variant="bodyBold">{title}</Typography>
              {subtitle && <Typography variant="body">{subtitle}</Typography>}

              <LinkLabel
                disabled={addDisabled}
                label={t('common.add')}
                onClick={open}
              />
            </Stack>
            {!!files?.length && (
              <Pressed onClick={() => setShowFiles((v) => !v)}>
                <Icon.ArrowDown className="filesUpload-arrowFile" />
              </Pressed>
            )}
          </Stack>
          {!!files?.length && <LineStyled />}
          {showFiles && !!files?.length && (
            <Stack datax="documents" spacing={32}>
              {filesContainer}
            </Stack>
          )}
        </Stack>
      )}
    </ContainerStyled>
  );
};
