import { ComponentType } from 'react';

import { ConfirmationDialog } from 'components';
import { useTranslation } from 'libs/i18n';
import { Pressed, Stack, Typography } from 'libs/ui';
import { useDialog } from 'libs/ui/Dialog/useDialog';
import { Icon } from 'libs/ui/Icon';
import { SvgProps } from 'libs/ui/Icon/icons/types';
import { useTheme } from 'libs/ui/theme';

import { ErrorStyled, ItemStyled } from './styles';

export interface ItemType {
  id: string;
  type: string;
  name: string;
  Icon: ComponentType<SvgProps>;
}

export interface ItemProps {
  item: ItemType;
  deleteLoading?: boolean;
  error?: boolean;
  errorText: string;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

export const Item = ({
  item,
  deleteLoading,
  error,
  errorText,
  onEdit,
  onDelete,
}: ItemProps) => {
  const { isOpen, onClose, open } = useDialog();
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <Stack>
      <Stack
        alignItems="center"
        datax="listItem-container"
        direction="row"
        justifyContent="space-between"
        spacing={12}
      >
        <ItemStyled alignItems="center" direction="row" spacing={16}>
          <item.Icon size={62} />
          <Stack className="listItem-textContent">
            <Typography
              className="listItem-text"
              title={item.name}
              variant="bodyBold"
            >
              {item.name}
            </Typography>
            <Typography className="listItem-text" title={item.type}>
              {item.type}
            </Typography>
          </Stack>
        </ItemStyled>

        <Stack alignItems="center" direction="row" spacing={12}>
          <Pressed datax="listItem-edit" onClick={() => onEdit(item.id)}>
            <Icon.Edit />
          </Pressed>

          <Pressed datax="listItem-delete" onClick={open}>
            <Icon.Bucket />
          </Pressed>
        </Stack>
      </Stack>

      {error && (
        <ErrorStyled>
          <Typography color={theme.palette.error.main}>{errorText}</Typography>
        </ErrorStyled>
      )}

      <ConfirmationDialog
        fullWidth
        headerCentered
        confirmButtonColorVariant="red"
        confirmButtonLabel={t('common.delete')}
        isOpen={isOpen}
        loading={deleteLoading}
        subtitle={t('components.confirmDelete.subtitle')}
        title={t('components.confirmDelete.title')}
        onBack={onClose}
        onClose={onClose}
        onConfirm={() => onDelete(item.id)}
      />
    </Stack>
  );
};
