import { Box } from '../../Box';
import { CircularProgress } from '../../CircularProgress';
import { Icon } from '../../Icon';
import { Pressed } from '../../Pressed';
import { Stack } from '../../Stack';
import { Typography } from '../../Typography';
import { FileUpload } from '../types';

import { ContainerStyled, DocumentNumberStyled } from './FileContainer.styles';

const bytesToMegaBytes = (sizeInBytes: number) =>
  Math.max(sizeInBytes / (1024 * 1024), 0.1).toFixed(1);

interface Props<T extends FileUpload> {
  file: T;
  index: number;
  onRemoveFile: () => void;
}

export const FileContainer = <T extends FileUpload>({
  file,
  index,
  onRemoveFile,
}: Props<T>) => (
  <ContainerStyled
    key={file.id}
    alignItems="center"
    datax="fileContainer"
    direction="row"
    justifyContent="space-between"
  >
    <Stack
      key={file.fileInfo.name}
      className="fileContainer-content"
      direction="row"
      spacing={28}
    >
      <DocumentNumberStyled>
        <Icon.Document />
        <Box className="document-number">
          <Typography variant="captionBold">{index + 1}</Typography>
        </Box>
      </DocumentNumberStyled>
      <Stack className="fileContainer-fileInfo" spacing={4}>
        <Typography className="fileContainer-fileName" variant="bodyBold">
          {file.fileInfo.name}
        </Typography>
        <Typography>{bytesToMegaBytes(file.fileInfo.size)} Mb</Typography>
      </Stack>
    </Stack>
    {file.loading ? (
      <CircularProgress size={16} />
    ) : (
      <Pressed datax="detele" onClick={onRemoveFile}>
        <Icon.Close size={16} />
      </Pressed>
    )}
  </ContainerStyled>
);
